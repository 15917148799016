import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { City } from '../interfaces/city';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../interfaces/api-response';
/**
 * City search service
 *
 * This service is used to search cities for the autocompleted city input
 */
@Injectable({
  providedIn: 'root'
})
export class CitySearchService {
  /**
   * Constructor
   * @param {HttpClient} http Angular's Http service
   * @see {@link https://angular.io/api/common/http/HttpClient}
   */
  constructor(private http: HttpClient) { }

  /**
   * All cities accessor
   *
   * Depending on the environment, MeetUp searching can load all cities for autocompletion
   * Maybe very heavy for large database
   * @returns {Observable<City[]>} All cities
   * @see http://chat_api-pp.contactdve.com/doc/#api-City-Get_cities_by_parameters
   */
  // get all(): Observable<City[]> {
  //   return this.byName();
  // }

  /**
   * Search cities by name
   * @param {string} name city name part
   * @returns {Observable<City[]>} Cities corresponding to the name part searched
   * @see http://chat_api-pp.contactdve.com/doc/#api-City-Get_cities_by_parameters
   */
  byName(limit: number, name?: string): Observable<City[]> {
    let params = new HttpParams()
      .set('key', environment.key.toString())
      .set('offset', '0')
      .set('limit', limit.toString())
      .set('country', environment.extCode);
    if (name) {
      params = params.set('city_name', name);
    }
    return this.http
      .get<ApiResponse<{cities: City[]}>>(`${environment.api}/all_cities`, { params })
      .pipe(
        map(res => {
          return res.data ? res.data.cities as City[] : [];
        })
      );
  }
}
