import { Pipe, PipeTransform } from '@angular/core';

/**
 * User age pipe
 *
 * Transform a birthdate into date
 * @see {@link https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Date}
 */
@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  /**
   * The actual date
   */
  readonly now = new Date();

  /**
   * Date of the first day
   */
  readonly origin = new Date(0);

  /**
   * Transform a birthdate into an age
   * @param {Date|string} birthday ISO 8601 string birthdate or date object
   * @returns {number} The age
   */
  transform(birthday: Date|string): number {
    if (typeof birthday === 'string') {
      birthday = new Date(birthday as string);
    }
    // This part is a bit complicated but it prevent annoying calculation for age (february i hate you ><)
    const ageDate = new Date(this.now.getTime() - birthday.getTime());
    return ageDate.getUTCFullYear() - this.origin.getUTCFullYear();
  }

  /**
   * Calculate birthdate from age
   *
   * This is not really possible, but it helps...
   * The birthdate is calculate from the current date minus age (amount of years)
   * @param {number} age Age (amount of years)
   */
  birthdateFromAge(age: number): string {
    const year = this.now.getUTCFullYear() - age;
    const month = this.now.getUTCMonth() + 1;
    const day = this.now.getUTCDate();
    return `${year}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`;
  }
}
