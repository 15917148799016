import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/account/user.service';
import { Angulartics2 } from 'angulartics2';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { AgePipe } from 'src/app/shared/pipe/age.pipe';
import { Gender } from 'src/app/shared/enums/gender.enum';
import { LookingFor } from 'src/app/shared/enums/looking-for.enum';
import { User } from 'src/app/shared/interfaces/user';
import { City } from 'src/app/shared/interfaces/city';
import { TokenService } from 'src/app/account/token.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  readonly agePipe = new AgePipe;
  readonly Gender = Gender;
  readonly LookingFor = LookingFor;
  readonly LP = environment.LP;
  public mask = [/[\d\+\(]/, /[\d\s\(\)\+]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/,
    /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/,
    /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/,
    /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/, /[\d\s\(\)]/];

  country = environment.extCode;
  hide = true;
  user: User = {
    id: null,
    nickname: null,
    password: '',
    birthdate: this.agePipe.birthdateFromAge(35),
    gender: Gender.man,
    date_online: new Date(),
    city: this.getCity(),
    description: null,
    email: null,
    phone: null,
    under_your_blacklist: false,
    search: null,
    looking_for: 2,
    avatar: null,
    avatars: [],
    logged: false,
    is_animator: false,
    ga_details: null,
    sb_token: null,
    is_moderator: false,
    hobbies: null
  };
  form: FormGroup;
  constructor(
    private users: UserService,
    private tracker: Angulartics2,
    private formBuilder: FormBuilder,
    private token: TokenService,
    private router: Router
  ) {
    this.form = formBuilder.group({
      password: formBuilder.control(null, [Validators.required, Validators.minLength(5)])
    });
  }

  ngOnInit() {
    this.users.checkRegistration().subscribe((data: { redirect: string, redirectPage: string; user: User, msisdn: string }) => {
      const isMsisdn = environment.completeProfileInputs.includes('phoneNumber');
      const isEmail = environment.completeProfileInputs.includes('email');
      if (isMsisdn) {
        this.form.addControl(
          'phone', this.formBuilder.control(data.msisdn, [Validators.required, Validators.minLength(8)])
        );
        this.user.phone = data.msisdn;
      }
      if (isEmail) {
        this.form.addControl(
          'email', this.formBuilder.control(null, [Validators.required])
        );
      }
    });
  }
  getCity(): City {
    this.users.getServiceCapitale().subscribe(data => {
      if (data.id) {
        return this.user.city = data;
      }
    });
    return null;
  }
  setGender(gender) {
    this.user.gender = gender;
  }
  onSubmit() {
    this.user.password = this.form.value.password;
    this.user.email = this.form.value.email;
    this.user.phone = this.form.value.phone;
    // this.loading = true;
    this.users.registerUser(this.user)
      .subscribe((data: { userauth: string, user: User }) => {
        this.token.set(data.userauth);
        this.router.navigate(['/chat/']);
        // this.customDimensions.set(response.data.user.ga_details);
        // const url = '/auto-sign/'
        //   + encodeURIComponent(data.userauth.toString()) + '/'
        //   + encodeURIComponent(data.user.ga_details.gender.toString()) + '/'
        //   + encodeURIComponent(data.user.ga_details.age.toString()) + '/'
        //   + encodeURIComponent(data.user.ga_details.profile_picture.toString()) + '/'
        //   + encodeURIComponent(data.user.ga_details.existence.toString()) + '/'
        //   + encodeURIComponent(data.user.ga_details.first_visite.toString());
        // this.router.navigate([url]);
        // this.tracker.push('/vpv/subscription_1step_validate_registration_V3', 'Registered');

        this.tracker.eventTrack.next({
          action: '/vpv/subscription_1step_validate_registration_V3',
          properties: {
            category: 'Registered'
          },
        });
      },
        err => {
          switch (err.error_code) {
            case 109:
              this.tracker.eventTrack.next({
                action: '/vpv/subscription_1step_incorrect_email_V3',
                properties: {
                  category: 'Email'
                },
              });
              break;
            case 110:
              this.tracker.eventTrack.next({
                action: '/vpv/subscription_1step_incorrect_phone_V3',
                properties: {
                  category: 'Phone'
                },
              });
              break;
            case 111:
              this.tracker.eventTrack.next({
                action: '/vpv/subscription_1step_incorrect_password_V3',
                properties: {
                  category: 'Password'
                },
              });
              break;
          }
        });
    // this.registerNewUser();
  }
  sendEmailTracker(event: Event): void {
    const pseudo = event.target as HTMLInputElement;
    if (pseudo.value.length === 1) {
      this.tracker.eventTrack.next({
        action: '/vpv/subscription_1step_clic_email_box_V3',
        properties: {
          category: 'Email'
        },
      });
    }
  }

  /** Send email input tracker when the user type almost 1 character*/
  sendPasswordTracker(event: Event): void {
    const password = event.target as HTMLInputElement;
    if (password.value.length === 1) {
      this.tracker.eventTrack.next({
        action: '/vpv/subscription_1step_clic_password_box_V3',
        properties: {
          category: 'Password'
        },
      });
    }
  }
}
