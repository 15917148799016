
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { CustomDimensionsService } from '../services/custom-dimensions.service';
import { environment } from '../../environments/environment';
import { ChatService } from '../chat/chat.service';
import { ActivatedRoute } from '@angular/router';
import { User } from '../shared/interfaces/user';
import { Conversation } from '../shared/interfaces/conversation';
import { ApiResponse } from '../shared/interfaces/api-response';
import { City } from '../shared/interfaces/city';
import { Angulartics2 } from 'angulartics2';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly onUserBlock = new Subject<User>();
  readonly onUserPseudoChange = new Subject<User>();
  readonly _logged = new Subject<User>();
  readonly conversations = new Subject<Conversation[]>();

  public conversationList: Conversation[];

  logged(): Observable<User> {
    return this._logged.asObservable();
  }
  unlogged() {
    this._logged.next(null);
  }
  blocked(): Observable<User> {
    return this.onUserBlock.asObservable();
  }
  getConversations(): Observable<any> {
    // console.log ('user service get conversations', this.conversations.asObservable());
    return this.conversations.asObservable();
  }
  setConversations(conversations: any) {
    this.conversationList = conversations;
    this.conversations.next(this.conversationList);
  }
  get shouldShowPopup(): Observable<boolean> {
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get());
    return this.http
      .get<ApiResponse<{ showPopup: boolean }>>(`${environment.api}/get_show_popup`, { params }).pipe(
        map(res => {
          return res.data.showPopup;
        })
      );
  }
  constructor(
    private http: HttpClient,
    private chat: ChatService,
    private token: TokenService,
    private customDimensions: CustomDimensionsService,
    private tracker: Angulartics2,
    private route: ActivatedRoute
  ) { }

  find(id?: number): Observable<User> {
    let params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get())
      .set('country', environment.extCode);
    if (id) {
      params = params.set('userId', id.toString());
    }
    return this.http
      .get<ApiResponse<User>>(`${environment.api}/get_user_data`, { params })
      .pipe(
        tap(res => {
          if (res.data.search) {
            res.data.search.distance = Math.floor(res.data.search.distance);
          }
        }),
        map(res => {
          if (!id) {
            this._logged.next(res.data);
            if ((window as any).dataLayer) {
              this.tracker.eventTrack.next({
                action: 'logged',
                properties: res.data.ga_details
              });
              (window as any).dataLayer.push(res.data.ga_details);
            }
          }
          return res.data;
        },
          error => console.error(error))
      );
  }

  logout(): void {
    this.token.remove();
    this.chat.disconnect();
    this._logged.next(null);
  }

  signIn(login: string, pass: string): Observable<void> {

    // tslint:disable-next-line:max-line-length
    const url = `${environment.api}/user_login?key=${environment.key.toString()}&country=${environment.extCode}&login=${login}&encryptedPassword=${encodeURIComponent(pass.toString())}`;
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse<{ operator: string; userauth: string; user: User }>>(url)
      .pipe(
        map((response) => {
          this.token.set(response.data.userauth);
          this.customDimensions.set(response.data.user.ga_details);
        })
      );
  }
  update(user: User): Observable<any> {
    const hobbies = [];
    user.hobbies.filter(h => {
      if (h.selected) {
        hobbies.push(h.id);
      }
    });
    const body = {
      key: environment.key.toString(),
      userauth: this.token.get(),
      password: user.password,
      description: user.description,
      status_description: '1',
      birthdate: user.birthdate.toString(),
      lookingFor: user.looking_for,
      city_id: user.city.id.toString(),
      hobbies,
      email: user.email ? user.email : '',
      pseudo: user.nickname
    };
    if (user.email) {
      body.email = user.email;
    }
    return this.http
      .put<ApiResponse<any>>(`${environment.api}/update_user`, body)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  registerUser(user: User): Observable<{ userauth: string, user: User }> {
    user.looking_for = user.gender === 1 ? 2 : 1;
    const body = new FormData();
    body.append('key', environment.key.toString());
    body.append('country', environment.extCode);
    body.append('password', user.password);
    body.append('gender', user.gender.toString());
    body.append('looking_for', user.looking_for.toString());
    body.append('guestToken', localStorage.getItem('guestToken') || this.uniqid());
    body.append('city', user.city.id.toString());

    if (this.route.snapshot.queryParams.SBtoken) {
      body.append('sb_token', this.route.snapshot.queryParams.SBtoken);
    }
    if (this.route.snapshot.queryParams.o) {
      body.append('o', this.route.snapshot.queryParams.o);
    }
    if (environment.extCode === 'ES') {
      body.append('nickname', user.phone);
    } else {
      if (user.nickname) {
        body.append('nickname', user.nickname);
      }
    }
    if (environment.completeProfileInputs.indexOf('email') >= 0) {
      body.append('email', user.email);
    }
    if (environment.completeProfileInputs.indexOf('phoneNumber') >= 0) {
      body.append('phoneNumber', user.phone.replace(/ /g, '').replace(/\)/g, '').replace(/\(/g, ''));
    }

    body.append('paramsUrl', encodeURIComponent(JSON.stringify(this.route.snapshot.queryParams)));

    return this.http
      .post<ApiResponse<{ userauth: string, user: User }>>(`${environment.api}/signup_user`, body)
      .pipe(
        map(res => {
          if (res.code === 500) {
            throw (res);
          }
          return res.data;
        })
      );
  }

  uniqid() {
    return (new Date().getTime() + Math.floor((Math.random() * 1000000) + 1)).toString(16);
  }
  getServiceCapitale(): Observable<City> {
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('country', environment.extCode);
    return this.http
      .get<ApiResponse<{ city: City }>>(`${environment.api}/get_service_capital`, { params })
      .pipe(
        map(res => {
          return res.data.city;
        })
      );
  }

  checkRegistration(queryParams = null): Observable<{ redirect: string; user: User; msisdn: string }> {
    if (queryParams == null) {
      queryParams = this.route.snapshot.queryParams;
    }

    let params = new HttpParams()
      .set('key', environment.key.toString())
      .set('country', environment.extCode);
    if (this.token.get()) {
      params = params.set('userauth', this.token.get());
    }
    if (queryParams.userDveMsisdn) {
      params = params.set('userDveMsisdn', queryParams.userDveMsisdn);
    }
    if (queryParams.o) {
      params = params.set('o', queryParams.o);
    }
    if (queryParams.userDveId) {
      params = params.set('userDveId', queryParams.userDveId);
    }
    if (queryParams.SBtoken) {
      params = params.set('SBtoken', queryParams.SBtoken);
    } else if (localStorage.getItem('SBtoken')) {
      params = params.set('SBtoken', localStorage.getItem('SBtoken'));
    }
    params = params.set('paramsUrl', encodeURIComponent(JSON.stringify(queryParams)));

    return this.http
      .get<ApiResponse<{
        redirect: string;
        redirectPage: string;
        user: User; msisdn: string
      }>>(`${environment.api}/check_registration`, { params })
      .pipe(
        map(res => {
          return res.data;
        })
      );
  }
  deleteAccount(id: string, pwd: string): Observable<any> {
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get())
      .set('userId', id)
      .set('password', pwd);

    return this.http
      .delete<ApiResponse<void>>(`${environment.api}/remove_user_data`, { params })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  block(user: User): Observable<any> {
    const body = new FormData();
    body.append('key', environment.key.toString());
    body.append('userauth', this.token.get());
    body.append('userId', user.id.toString());
    return this.http
      .post<ApiResponse<any>>(`${environment.api}/add_to_blacklist`, body)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  unblock(user: User): Observable<any> {
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get())
      .set('userId', user.id.toString());
    return this.http
      .delete<ApiResponse<any>>(`${environment.api}/del_from_blacklist`, { params })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  blockAndReport(user: User): Observable<void> {
    const body = new FormData();
    body.append('key', environment.key.toString());
    body.append('userauth', this.token.get());
    body.append('userId', user.id.toString());
    return this.http
      .post<ApiResponse<void>>(`${environment.api}/add_to_blacklist_report`, body)
      .pipe(
        map(res => {
          return;
        }),
        tap(() => this.onUserBlock.next(user))
      );
  }
  sendPasswordRecovery(emailOrPhone: string): Observable<boolean> {
    let datas;
    // const params = new HttpParams()
    //   .set('key', environment.key.toString())
    //   .set('country', environment.extCode)
    //   .set('userauth', this.token.get());

    console.log (emailOrPhone, isNaN(Number(emailOrPhone)), Number(emailOrPhone));
    if (isNaN(Number(emailOrPhone))) {
      datas = {
        key: environment.key.toString(),
        country: environment.extCode,
        email: emailOrPhone
      };
    } else {
      datas = {
        key: environment.key.toString(),
        country: environment.extCode,
        phone_number: emailOrPhone
      };
    }
    return this.http
      .get<ApiResponse<any>>(`${environment.api}/send_password_recovery_email`, { params: datas })
      .pipe(
        map(res => {
          console.log(res.code);
          return res.code === 200;
        })
      );
  }

}
