import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './account/user.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { TokenService } from './account/token.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedGuard implements CanActivate {
  constructor(
    private router: Router,
    private token: TokenService,
    private user: UserService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      console.log (!!this.token.get());
      return of(!!this.token.get()).pipe(
        map(token => {
          // this.user._logged.next(true);
          if (token && next.routeConfig.path === 'connection') {
            this.redirect('/chat');
          }
          return true;
        }),
        catchError(() => {
          this.redirect('/connection');
          return of(false);
        })
      );
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    // return this.canActivate(next, state);
        console.log ('can activate Child', !!this.token.get());
    return of(!!this.token.get()).pipe(
      map(token =>  {
        if (!token) {
          this.redirect('/connection');
        }
        return token;
      }),
      catchError(() => {
        this.redirect('/connection');
        return of(false);
      })
    );
  }
  redirect(url) {
    this.router.navigate([url]);
  }
}
