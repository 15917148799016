import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { TokenService } from '../account/token.service';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Conversation } from 'src/app/shared/interfaces/conversation';
import { ApiResponse } from 'src/app/shared/interfaces/api-response';

@Injectable({
  providedIn: 'root'
})
export class ConversationsService {

  constructor(
    private http: HttpClient,
    private token: TokenService
  ) { }

  conversationList(
    limit: number,
    offset: number): Observable<Conversation[]> {
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get())
      .set('limit', limit.toString())
      .set('offset', offset.toString());
    return this.http
      .get<ApiResponse<{ conversations: Conversation[] }>>(`${environment.api}/get_conversation_list`, { params }).pipe(
        map(res => res.data.conversations));
  }
  getConversartion(conversationId: number): Observable<Conversation> {
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get())
      .set('conversationId', conversationId.toString());
    return this.http
      .get<ApiResponse<{conversation: Conversation}>>(`${environment.api}/get_conversation_by_id`, { params }).pipe(
        map(res => res.data.conversation));
  }
}
