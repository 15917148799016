import { Component, OnInit, Input } from '@angular/core';
import { transition, trigger, style, animate } from '@angular/animations';
import { AppService } from 'src/app/app.service';
import { Conversation } from '../../interfaces/conversation';
import { ChatMessageService } from 'src/app/chat/chat-message.service';
import { User } from '../../interfaces/user';
import { Avatar } from '../../interfaces/avatar';
import { AvatarService } from 'src/app/account/account-profil/account-profil-avatars/avatar.service';
import { Angulartics2 } from 'angulartics2';
import { UserService } from 'src/app/account/user.service';
import { TokenService } from 'src/app/account/token.service';
import { ChatService } from 'src/app/chat/chat.service';
import { LoggedGuard } from 'src/app/logged.guard';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'], animations: [
    trigger('fade', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate('.2s ease-in', style({
          opacity: 1
        }))
      ]),
      transition(':leave', [
        animate('.1s .2s ease-in', style({
          opacity: 0
        }))
      ]),
    ]),
    trigger('ripple', [
      transition(':enter', [
        style({
          transform: 'scale(0)',
          opacity: 1
        }),
        animate('.3s ease-in', style({
          transform: 'scale(1)',
          opacity: 0
        }))
      ]),
      transition(':leave', [
        style({
          transform: 'scale(0)',
          opacity: 1
        }),
        animate('.3s ease-in', style({
          transform: 'scale(1)',
          opacity: 0
        }))
      ]),
    ]),
    trigger('plop', [
      transition(':enter', [
        style({
          transform: 'scale(0)'
        }),
        animate('.1s ease-in', style({
          transform: 'scale(1)'
        }))
      ]),
      transition(':leave', [
        animate('.1s .2s ease-in', style({
          transform: 'scale(0)'
        }))
      ]),
    ]),
    trigger('plop2', [
      transition(':enter', [
        style({
          transform: 'scale(0)'
        }),
        animate('.1s .1s ease-in', style({
          transform: 'scale(1)'
        }))
      ]),
      transition(':leave', [
        animate('.1s .1s ease-in', style({
          transform: 'scale(0)'
        }))
      ]),
    ]),
    trigger('fade2', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate('.1s .3s ease-in', style({
          opacity: 1
        }))
      ]),
      transition(':leave', [
        animate('.1s ease-in', style({
          opacity: 0
        }))
      ]),
    ])
  ]
})
export class BlockComponent implements OnInit {
  @Input() popup: { type: string, conversation?: Conversation, user?: User, avatar?: Avatar };

  readonly unsubscriptionLink = environment.unsubscribeLink !== undefined ? environment.unsubscribeLink : false;

  pwd: string;
  hiddenPassword = true;
  firstDisplay = true;
  confirmType = '';

  submiting = false;
  submitOk = false;
  deleteConfirm = false;
  reportReason: string;

  passwordEmpty = false;
  passwordWrong = false;
  // @Input() type: string;
  // @Input() data: {conversation?: Conversation, user?: User };

  constructor(
    private dialog: AppService,
    private message: ChatMessageService,
    private avatars: AvatarService,
    private tracker: Angulartics2,
    private users: UserService,
    private token: TokenService,
    private chat: ChatService,
    private guard: LoggedGuard
  ) { }

  ngOnInit() {
  }
  removeConv() {
    this.message.removeConversation(this.popup.conversation).subscribe(() => {
      this.close();
    });
  }
  removeAvatar() {
    this.avatars.deleteAvatar(this.popup.avatar)
      .subscribe(() => {
        this.dialog.throwDialog({ display: false, type: 'confirmed' });
      });
  }
  close() {
    this.dialog.throwDialog({ display: false, type: null });
  }
  onSubmit() {
      this.users.deleteAccount(this.popup.user.id.toString(), this.pwd).subscribe(res => {
        if (res.code === 200) {
          this.deleteConfirm = true;
          this.chat.disconnect();
          this.token.remove();
          this.guard.redirect('/connection');
        } else {
          this.passwordWrong = true;
        }
      });
  }
  blockAndReport() {
    this.users.blockAndReport(this.popup.user)
      .subscribe(res => {
        // user.under_your_blacklist = true;
      });
  }
  block(): void {
    this.submiting = true;
    this.users.block(this.popup.user)
      .subscribe(res => {
        this.submitOk = true;
        this.submiting = false;
        if (res.code === 200) {
          setTimeout(() => this.close(), 3000);
          this.popup.user.under_your_blacklist = true;
          this.users.onUserBlock.next(this.popup.user);
        } else {
          this.close();
        }
      });
  }
  showConfirm(type: string) {
    this.firstDisplay = false;
    this.confirmType = type;
  }
  cancelConfirm() {
    this.firstDisplay = true;
  }
}
