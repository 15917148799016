import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { transition, trigger, query, style, animate, stagger } from '@angular/animations';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate('.4s ease-out', style({
          opacity: 1
        }))
      ]),
    ]),
    trigger('ripple', [
      transition(':enter', [
        style({
          transform: 'scale(0)',
          opacity: 1
        }),
        animate('.4s ease-out', style({
          transform: 'scale(1)',
          opacity: 0
        }))
      ]),
    ]),
    trigger('plop', [
      transition(':enter', [
        style({
          transform: 'scale(0)'
        }),
        animate('.15s ease-out', style({
          transform: 'scale(1)'
        }))
      ]),
    ]),
    trigger('plop2', [
      transition(':enter', [
        style({
          transform: 'scale(0)'
        }),
        animate('.15s .1s ease-out', style({
          transform: 'scale(1)'
        }))
      ]),
    ])
  ]
})
export class ErrorComponent implements OnInit {
  // @HostBinding('@fade')
  @Input () message: String;
  @Input () code: number;
  @Input () error: string;

  min = 3;
  max = 25;

  minLogin = 3;
  maxLogin = 25;

  constructor(
    private dialog: AppService
  ) { }

  ngOnInit() {
    console.log (this.message, this. code, this.error);
  }

  close() {
    this.dialog.throwError( false );
  }

}
