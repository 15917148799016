import { Injectable } from '@angular/core';
import { CustomDimensions } from '../shared/interfaces/custom-dimensions';

/** GTM Custom dimensions service */
@Injectable({
  providedIn: 'root'
})
export class CustomDimensionsService {

  /** The localstorage key where the custom dimensions will be stored */
  private readonly key = 'customDimensions';

  /**
   * Getter
   *
   * Retrieve custom dimensions and parse it as JSON
   * @returns {CustomDimensions} the curstom dimensions as JSON
   */
  get(): CustomDimensions {
    return JSON.parse(localStorage.getItem(this.key));
  }

  /**
   * Setter
   *
   * Store custom dimensions as string from JSON inside localstorage
   * @param {CustomDimensions} customDimensions curstom dimensions to store
   */
  set(customDimensions: CustomDimensions): void {
    localStorage.setItem(this.key, JSON.stringify(customDimensions));
  }

}
