<div class="main-background" [class.logged]="logged" [class.desktop]="desktop" [class.rtl]="rtl"
  [class.offline]="!online"></div>
<main [ngClass]="[ desktop ? 'desktop' : 'mobile', rtl ? 'rtl' : 'ltr' ]" [class.offline]="!online"
  [dir]="rtl ? 'rtl' : 'ltr'">
  <app-account *ngIf="logged"></app-account>
  <div class="main-content" [class.logged]="logged">
    <app-conversations *ngIf="logged else home"></app-conversations>
  </div>
  <app-flap [desktop]="desktop" [logged]="logged"></app-flap>
</main>
<ng-template #home>
  <ng-container *ngIf="online else offline">
    <app-home></app-home>
  </ng-container>
</ng-template>
<app-notifications></app-notifications>
<app-error *ngIf="error.display" [message]="error.message" [code]="error.code" [error]="error.error"></app-error>
<app-block *ngIf="popup.display" [popup]="popup"></app-block>
<app-focus-input *ngIf="showInputFocus.show" [val]="showInputFocus.value" [call]="showInputFocus.call">
</app-focus-input>
<footer *ngIf="footerDisplay">
  <button class="legal button button-round lighten right" (click)="toggleLegal()"
    *ngIf="(!legalDisplay) && footerDisplay">
    <mat-icon svgIcon="information"></mat-icon>
  </button>
  <button class="legal button button-icon lighten right" (click)="toggleLegal()" *ngIf="legalDisplay">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <app-footer (hideLegal)="toggleLegal()" [displayBanner]="displayBanner" *ngIf="legalDisplay"></app-footer>
</footer>
<ng-template #offline>
  <div class="banner-offline" *ngIf="!online">
    <mat-icon svgIcon="nointernet"></mat-icon>
    <ng-container i18n="@@Internetconnexionrequired">Vous n'êtes pas connecté à un réseau ...</ng-container>
  </div>
</ng-template>
