
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Conversation } from '../shared/interfaces/conversation';
import { TokenService } from '../account/token.service';
import { ApiResponse } from '../shared/interfaces/api-response';
import { User } from '../shared/interfaces/user';
import { Message } from '../shared/interfaces/message';

/**
 * Messages service
 *
 * This class communicate with the API to manipulate messages and conversations.
 * {@link ChatService} use this service. This class doesn't provide Real-Time interaction.
 */
@Injectable({
  providedIn: 'root'
})
export class ChatMessageService {

  /** A conversation should been removed */
  readonly onConversationDelete = new Subject<Conversation>();

  /**
   * Constructor
   * @param {HttpClient} http Http client service
   * @param {TokenService} token Token access service
   * @see {@link https://angular.io/api/common/http/HttpClient}
   */
  constructor(
    private http: HttpClient,
    private token: TokenService,
  ) { }

  /**
   * Get the conversation list of the current user
   * @param {number} limit Limit (SQL) of messages
   * @param {number} offset Offset (SQL) of messages
   * @returns {Observable<Conversation[]>} The conversation list of the user
   * @throws {Error} error if the conversation list can be get
   * @see http://chat_api-pp.contactdve.com/doc/#api-Message-Get_the_conversation_s_list
   */
  conversationList(
    limit?: number,
    offset?: number): Observable<Conversation[]> {
    let params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get());
    if (limit) {
      params = params.set('limit', limit.toString());
    }
    if (offset) {
      params = params.set('offset', offset.toString());
    }
    return this.http
      .get<ApiResponse<{ conversations: Conversation[] }>>(`${environment.api}/get_conversation_list`, { params }).pipe(
        map(res => {
          if (res.error) {
            throw new Error(res.msg as string);
          }
          return res.data.conversations;
        }));
  }

  /**
   * Get the messages of a conversation between the current user and another one
   * @param {User} interlocutor The interlocutor of the conversation
   * @param {number} limit Limit of get messages (MySQL limit)
   * @param {number} offset Limit offset of the get messages (MySQL offset)
   * @returns {Observable<Message[]>} Message list of the conversation
   * @throws {Error} error if the conversation list can be get
   * @see http://chat_api-pp.contactdve.com/doc/#api-Message-Get_conversation_s_messages
   */
  conversationMessages(interlocutor: User, limit = 10, offset = 0): Observable<Message[]> {
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get())
      .set('interlocutorId', interlocutor.id.toString())
      .set('country', environment.extCode)
      .set('offset', offset.toString())
      .set('limit', limit.toString());
    return this.http
      .get<ApiResponse<{ messages: Message[] }>>(`${environment.api}/get_conversation_messages`, { params }).pipe(
        map(res => {
          if (res.error) {
            throw new Error(res.msg as string);
          }
          return res.data.messages;
        }));
  }

  /**
   * Mark a message as read
   * @param {Message} message The read message
   * @returns {Observable<void>} An empty observable
   * @throws {Error} error if the message can't be marked as read
   * @see http://chat_api-pp.contactdve.com/doc/#api-Message-Mark_message_as_read
   */
  markMessageAsRead(message: Message): Observable<void> {
    const body = {
      key: environment.key.toString(),
      userauth: this.token.get(),
      msgId: message.id.toString()
    };
    return this.http
      .put<ApiResponse<void>>(`${environment.api}/mark_msg_as_read`, body).pipe(
        map(res => {
          if (res.error) {
            throw new Error(res.msg as string);
          }
          return;
        }));
  }

  /**
   * Remove a conversation of the current user
   * @param {Conversation} conversation The conversation to remove
   * @returns {Observable<Conversation>} The removed conversation
   * @throws {Error} error if the conversation can't be removed
   * @see http://chat_api-pp.contactdve.com/doc/#api-Message-Remove_conversation
   */
  removeConversation(conversation: Conversation): Observable<Conversation> {
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('userauth', this.token.get())
      .set('conversationId', conversation.id.toString());
    return this.http
      .delete<ApiResponse<object>>(`${environment.api}/del_conversation`, { params }).pipe(
        map(res => {
          if (res.error) {
            throw new Error(res.msg as string);
          }
          this.onConversationDelete.next(conversation);
          return conversation;
        }));
  }

  /**
   * Send a message to an user from the current user
   * @param {User} receiver the message receiver
   * @param {string} message the message body
   * @returns {Observable<Message>} The sent message
   * @throws {MessageError} error if the message can't be sent
   * @see http://chat_api-pp.contactdve.com/doc/#api-Message-Send_message_to_user
   */
  sendMessage(receiver: User, message: string, photo: boolean): Observable<Message> {
    const now = new Date();
    const body = new FormData();
    body.append('key', environment.key.toString());
    body.append('userauth', this.token.get());
    body.append('country', environment.extCode);
    body.append('recipientId', receiver.id.toString());
    body.append('timeZone', now.getTimezoneOffset().toString());
    if (photo) {
      body.append('image', message);
    } else {
      body.append('msgBody', message);
    }
    return this.http
      .post<ApiResponse<{ message: Message }>>(`${environment.api}/send_msg_to_user`, body).pipe(
        map(res => {
          console.log ('chat message service send message return', res.data.message);
          return res.data.message;
        }));
  }
}
