/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, illimitado chat',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',
  extCode: 'MX',
  key: 1,
  newsSectionUrl: 'https://m.weezchat.mx/news',
  footerText: 'Atención al cliente de lunes a viernes de 9.00 a 18:00 al 01 800 288 99 79',
  footerLinks: [
    { value: 'TYC', href: 'http://m.weezchat.mx/terminos' },
    { value: 'Aviso de privacidad', href: 'http://m.weezchat.mx/aviso' },
    { value: 'Inicio', href: '/chat/meetUp' },
    { value: 'Cancelar suscripción', href: 'http://m.weezchat.mx/unsubscribe' },
    { value: 'Mi cuenta', href: 'http://m.weezchat.mx/micuenta' },
  ],
  LP: 'http://portalweezchat.tucelular.mx/in/nNT4iKNmI1r1OO7AcGUg',
  redirectIfNoIframe: null,
  useBothSignUpMethod: true,
  avatarSize: 1200,
  // gtm: 'GTM-TLNVLM',
  gtm: 'GTM-T4W6X2V',
  showAllCities: false,
  completeProfileInputs: ['phoneNumber'],
  RTL: false,
  unsubscribeLink: 'http://m.weezchat.mx/terminos',
  freeSubscription : true,
  home: {
    app: {
      available: false,
      linkGoogle: '',
      linkApple: '',
    },
    facebook: {
      available: true,
      link: 'https://www.facebook.com/WeezchatMX/'
    },
    twitter: {
      available: true,
      link: 'https://twitter.com/Weezchat_mx'
    },
    instagram: {
      available: true,
      link: 'https://www.instagram.com/weezchat_mexico/'
    }
  },
  RGPD : {
    cookies: {
      available: true,
      link: 'https://www.dgp-legal.com/en/cookies-information/'
    }
  }
};
