import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { TokenService } from './account/token.service';
import { AppService } from './app.service';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Observable, fromEvent } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  get logged(): boolean {
    return !!this.token.get();
  }

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  online = true;

  error = {
    display: false,
    message: '',
    code: null,
    error: null
  };
  popup = {
    display: false,
    type: null
  };

  title = 'app';

  desktop: boolean;
  rtl: boolean;

  legalDisplay = false;
  displayBanner = false;
  footerDisplay = true;
  showInputFocus = {
    show: false,
    value: '',
    call: false
  };

  constructor(
    @Inject(LOCALE_ID) locale: string,
    gtm: Angulartics2GoogleTagManager,
    sanitizer: DomSanitizer,
    iconRegistry: MatIconRegistry,
    title: Title,
    private token: TokenService,
    private dialog: AppService,
  ) {
    gtm.startTracking();

    title.setTitle(environment.title);
    this.rtl = environment.RTL;
    document.documentElement.setAttribute('lang', locale);
    this.desktop = window.innerWidth > 960;
    let agrement = !!localStorage.getItem('cookieAgrement');
    if (agrement) {
      agrement = Number(localStorage.getItem('cookieAgrement')) + (1000 * 3600 * 24 * 30 ) > Date.now();
    }
    this.displayBanner = this.legalDisplay = !agrement;
    iconRegistry
      .addSvgIcon('meet', sanitizer.bypassSecurityTrustResourceUrl(`assets/svg/${environment.extCode === 'EG' ? 'friends' : 'heart'}.svg`))
      .addSvgIcon('menu', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/menu.svg'))
      .addSvgIcon('warning', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/warning.svg'))
      .addSvgIcon('visible', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/visible.svg'))
      .addSvgIcon('hide', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hide.svg'))
      .addSvgIcon('reply', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/reply.svg'))
      .addSvgIcon('emoji', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/emoji.svg'))
      .addSvgIcon('pics', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/pics.svg'))
      .addSvgIcon('send', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/send.svg'))
      .addSvgIcon('app', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/app.svg'))
      .addSvgIcon('countries', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/countries.svg'))
      .addSvgIcon('facebook', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/facebook.svg'))
      .addSvgIcon('twitter', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/twitter.svg'))
      .addSvgIcon('instagram', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/instagram.svg'))
      .addSvgIcon('concept', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/concept.svg'))
      .addSvgIcon('email', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/email.svg'))
      .addSvgIcon('ok', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ok.svg'))
      .addSvgIcon('denied', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/denied.svg'))
      .addSvgIcon('key', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/key.svg'))
      .addSvgIcon('like', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/like.svg'))
      .addSvgIcon('disconnect', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/disconnect.svg'))
      .addSvgIcon('profil', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profil.svg'))
      .addSvgIcon('lock', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lock.svg'))
      .addSvgIcon('unlock', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/unlock.svg'))
      .addSvgIcon('lamp', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lamp.svg'))
      .addSvgIcon('messages', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/messages.svg'))
      .addSvgIcon('lock-conv', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lock-conv.svg'))
      .addSvgIcon('photo', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/photo.svg'))
      .addSvgIcon('photo-add', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/photo-add.svg'))
      .addSvgIcon('no-photo', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/nophoto.svg'))
      .addSvgIcon('woman', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/woman.svg'))
      .addSvgIcon('search', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/search.svg'))
      .addSvgIcon('man', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/man.svg'))
      .addSvgIcon('close', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/close.svg'))
      .addSvgIcon('location', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/location.svg'))
      .addSvgIcon('birthday', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/birthday.svg'))
      .addSvgIcon('pencil', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/pencil.svg'))
      .addSvgIcon('left-arrow', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/left-arrow.svg'))
      .addSvgIcon('right-arrow', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/right-arrow.svg'))
      .addSvgIcon('information', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/information.svg'))
      .addSvgIcon('filter', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/filter.svg'))
      .addSvgIcon('nointernet', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/nointernet.svg'))
      .addSvgIcon('HOBBY_1', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/sport.svg'))
      .addSvgIcon('HOBBY_3', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/travel.svg'))
      .addSvgIcon('HOBBY_5', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/cooking.svg'))
      .addSvgIcon('HOBBY_7', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/music.svg'))
      .addSvgIcon('HOBBY_9', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/cinema.svg'))
      .addSvgIcon('HOBBY_11', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/nature.svg'))
      .addSvgIcon('HOBBY_13', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/party.svg'))
      .addSvgIcon('HOBBY_15', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/painting.svg'))
      .addSvgIcon('HOBBY_17', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/gaming.svg'))
      .addSvgIcon('HOBBY_19', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/tv.svg'))
      .addSvgIcon('HOBBY_21', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/books.svg'))
      .addSvgIcon('HOBBY_23', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/hitek.svg'))
      .addSvgIcon('HOBBY_25', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/photo.svg'))
      .addSvgIcon('HOBBY_27', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/shopping.svg'))
      .addSvgIcon('HOBBY_29', sanitizer.bypassSecurityTrustResourceUrl('assets/svg/hobbies/theatre.svg'));

  }

  ngOnInit(): void {

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.online = navigator.onLine;
    this.online = true;
    // console.log ('on init', this.online);

    // this.onlineEvent.subscribe ( e => {
    //   this.online = true;
    //   console.log ('on onlineEvent', this.online);
    // });
    // this.offlineEvent.subscribe ( e => {
    //   this.online = false;
    //   console.log ('on offlineEvent', this.online);
    // });

    if (window.innerWidth <= 960) {
      this.dialog.state.subscribe(res => {
        this.showInputFocus = res;
      });
    }
    this.dialog.displayError().subscribe( err => {
      if (err) {
        this.error.display = true;
        this.error.message = err.message;
        this.error.code = err.code;
        this.error.error = err.error;
      } else {
        this.error.display = false;
        this.error.message = '';
        this.error.code = null;
      }
    });
    this.dialog.displayDialog().subscribe( dial => {
      this.popup = dial;
    });

    this.dialog.showHideFooter().subscribe( res => {
      this.footerDisplay = res;
    });
  }
  toggleLegal() {
    this.displayBanner = false;
    this.legalDisplay = !this.legalDisplay;
  }
}
