import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CityInputComponent } from './city-input/city-input.component';
import { MaterialModule } from './material.module';
import { BlockComponent } from './dialog/block/block.component';
import { ConfirmComponent } from './dialog/confirm/confirm.component';
import { ErrorComponent } from './dialog/error/error.component';
import { FocusInputComponent } from './focus-input/focus-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { AgePipe } from './pipe/age.pipe';
import { NouisliderModule } from 'ng2-nouislider';
import { ImageCropperModule } from 'ngx-image-cropper';
import { Angulartics2Module } from 'angulartics2';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CityInputComponent,
    BlockComponent,
    ConfirmComponent,
    ErrorComponent,
    FocusInputComponent,
    AgePipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    Angulartics2Module,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TextMaskModule,
    NouisliderModule,
    ImageCropperModule,
    Angulartics2Module,
    CityInputComponent,
    BlockComponent,
    ConfirmComponent,
    ErrorComponent,
    FocusInputComponent,
    AgePipe,
  ]
})
export class SharedModule { }
