import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as io from 'socket.io-client';
import { ChatMessageService } from './chat-message.service';
import { User } from '../shared/interfaces/user';
import { Message } from '../shared/interfaces/message';

/**
 * Chat service
 */
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public userConnected = false;

  /**
   * The Socket.IO instance
   */
  private socket: SocketIOClient.Socket;

  /**
   * Prefix of rooms name
   */
  private prefix = environment.extCode;

  /**
   * The interlocutor subject
   *
   * Can be used to detect room/interlocutor changing
   */
  readonly interlocutor = new BehaviorSubject<User>(null);

  /**
   * The last message sent to a conversation subject
   *
   * Useful to update conversation list
   */
  readonly lastMessage = new Subject<Message>();

  /**
   * Constructor
   * @param {MessageService} messages Messages services
   * @see {@link https://angular.io/api/common/http/HttpClient}
   */
  constructor(
    private messages: ChatMessageService
  ) { }

  /**
   * private room name builder
   * @param {User[]} users The users in the room
   * @returns {string} the name of the room
   */
  private roomName(users: number[]): string {
    let privateRoomName = this.prefix;
    users
      .sort((user1, user2) => user1 - user2)
      .map(user => {
        privateRoomName += `-${user}`;
      });
    return privateRoomName;
  }

  /**
   * The user connect the chat
   * @param {Profile} user The user profile
   */
  connect(user: User): void {
    this.userConnected = true;
    this.socket = io(environment.chat, {
      query: {
        id: user.id,
      }
    });
  }

  /**
   * The user disconnect
   */
  disconnect(): void {
    this.userConnected = false;
    this.socket.disconnect();
  }

  /**
   * Join room with specific interlocutor
   * @param {User} user The joining user
   * @param {User} interlocutor The interlocutor to join
   * @param {number} limit limit number of messages
   * @param {number} offset offset of messages
   */
  joinRoom(user: User, interlocutor: User, limit = 10, offset = 0): Observable<Message[]> {
    const room = this.roomName([user.id, interlocutor.id]);
    this.socket.emit('user join room', room);
    return this.messages.conversationMessages(interlocutor, limit, offset);
  }

  /**
   * Leave room with specific interlocutor
   * @param {User} user The leaver user
   * @param {User} interlocutor The interlocutor to leave
   */
  leaveRoom(user: User, interlocutor: User): void {
    if (user && interlocutor) {
      const room = this.roomName([user.id, interlocutor.id]);
      this.socket.emit('user leave room', room);
    }
  }

  /**
   * Send a message
   * @param {Message} message the message to send
   * @throws {Error} when the message can't be sent
   */
  sendMessage(message: Message, interlocutor: number): void {
    interlocutor = Number(interlocutor);
    const room = this.roomName([message.sender, interlocutor]);
    console.log (room);
    this.socket.emit('notification', message, interlocutor);
    this.socket.emit('message', message, room);
  }

  /**
   * Emit user typing
   * @param {User} user The typing user
   * @param {User} interlocutor The interlocutor
   */
  typing(user: User, interlocutor: User) {
    const room = this.roomName([user.id, interlocutor.id]);
    this.socket.emit('user start typing', room);
  }

  /**
   * Emit user stop typing
   * @param {User} user The user who has stopped typing
   * @param {User} interlocutor The interlocutor
   */
  stopTyping(user: User, interlocutor: User) {
    const room = this.roomName([user.id, interlocutor.id]);
    this.socket.emit('user stop typing', room);
  }

  /**
   * When user is logged
   * @return {Observable<User>} The profile of the user
   */
  get onLogin(): Observable<User> {
    return new Observable<User>(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('login', user => observer.next(user));
    });
  }

  /**
   * When the client is connected
   * @return {Observable<User>} The profile of the user
   */
  get onConnected(): Observable<User> {
    return new Observable<User>(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('user connected', user => observer.next(user));
    });
  }

  /**
   * Message reception
   *
   * When a message is received
   * @return {Observable<Message>} The message received
   */
  get onMessage(): Observable<Message> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('message', res => {
        if (!observer.closed) {
          observer.next(res);
          this.lastMessage.next(res);
        }
      });
    });
  }

  /**
   * notification reception
   *
   * When a notification is received
   * @return {Observable<Message>} The notification received
   */
  get onNotification(): Observable<Message> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('notification', res => observer.next(res));
    });
  }

  /**
   * Typing reception
   *
   * When a user is typing
   * @return {Observable<User>} The typing user
   */
  get onTyping(): Observable<User> {
    console.log ('chat service on typing');
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      console.log (observer);
      this.socket.on('user start typing', res => observer.next(res));
    });
  }

  /**
   * Typing stop reception
   *
   * When a user has stopped typing
   * @return {Observable<void>} The user who has stopped typing
   */
  get onTypingStop(): Observable<User> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('user stop typing', res => observer.next(res));
    });
  }

  /**
   * User logout
   * @return {Observable<User>} The user who has logout
   */
  get onUserLogout(): Observable<User> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('user logout', res => observer.next(res));
    });
  }

  /**
   * User login
   * @return {Observable<User>} The user who has login
   */
  get onUserLogin(): Observable<User> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('user login', res => observer.next(res));
    });
  }

  /**
   * NodeJS error
   * @return {Observable<Error>} Error send by NodeJS
   */
  get onError(): Observable<Error> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('error', res => observer.next(res));
    });
  }

  /**
   * When user socket is reconnected
   * @return {Observable<number>} Amount of attempts
   */
  get onReconnect(): Observable<number> {
    return new Observable(observer => {
      if (!this.socket) {
        observer.error(new Error('You are not connected to socket server'));
      }
      this.socket.on('reconnect', res => observer.next(res));
    });
  }
}
