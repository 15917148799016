<div class="footer">
  <div class="legal-overlay" (click)="closing();" [class.cookie]="displayBanner"></div>
  <div class="legal-background" [class.cookie]="displayBanner"></div>
  <div class="legal-content" [class.cookie]="displayBanner">
    <ng-container *ngIf="!displayBanner else banner">
      <p fxFlex="100" *ngIf="text">{{text}}</p>
      <div *ngFor="let link of links" class="legal">
        <a [href]="link.href" target="_blank">{{link.value}}</a>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #banner>
  <div class="banner-RGPD">
    <p i18n="Text cookie@@WeezchatCookies">En poursuivant la navigation, vous acceptez le dépôt de cookies destinés à
      optimiser votre navigation, réaliser des statistiques et à vous proposer des offres adaptées à vos centres
      d’intérêts.</p>
    <a i18n="bouton en savoir plus@@WeezchatCookiesButton"
    [href]="cookieLink" target="_blank"
    >En savoir plus</a>
  </div>
</ng-template>
