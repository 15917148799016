import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TokenService } from '../../token.service';
import { Avatar } from 'src/app/shared/interfaces/avatar';
import { ApiResponse } from 'src/app/shared/interfaces/api-response';

/**
 * User avatar service
 *
 * This service allow the user to change, delete an add avatars
 * It also provide a subject to watch when the current user change his/her avatar
 */
@Injectable({
  providedIn: 'root',
})
export class AvatarService {

  /** Event fired when the current user change his/her avatar */
  readonly onMainAvatarChange = new Subject<Avatar>();
  readonly onAddAvatar = new Subject<FileEvent>();
  readonly onAvatarDelete = new Subject<Avatar>();
  readonly onAvatarUpload = new Subject<Avatar>();

  /**
   * Constructor
   * @param {HttpClient} http Http client service
   * @param {TokenService} token Token access service
   * @see {@link https://angular.io/api/common/http/HttpClient}
   */
  constructor(private http: HttpClient, private token: TokenService) { }
  setAddAvatarSubject(value) {
    this.onAddAvatar.next(value);
  }
  cropAvatar(): Observable<any> {
    return this.onAddAvatar;
  }
  getNewAvatar(): Observable<Avatar> {
    return this.onAvatarUpload;
  }
  /**
   * Upload an avatar for the current user
   * @param {string} base64Image The avatar in base64 form
   * @returns {Observable<Avatar>} The avatar uploaded (contain urls of thumb and large format)
   * @throws {ImageError} An error if the upload failed
   * @see http://chat_api-pp.contactdve.com/doc/#api-Media-Upload_image
   */
  uploadAvatar(base64Image: string): Observable<Avatar> {
    const body = new FormData();
    body.append('key', environment.key.toString());
    body.append('userauth', this.token.get());
    body.append('image', base64Image);
    return this.http
      .post<ApiResponse<{ avatar: Avatar }>>(`${environment.api}/upload_image`, body)
      .pipe(
        map((res => {
          this.onAvatarUpload.next(res.data.avatar);
          return res.data.avatar;
        }))
      );
  }

  /**
   * Delete an avatar of teh current user
   * @param {Avatar} avatar The avatar to delete
   * @returns {Observable<void>} An empty observable
   * @throws {Error} An error if the deletion failed
   * @see http://chat_api-pp.contactdve.com/doc/#api-Media-User_delete_avatar
   */
  deleteAvatar(avatar: Avatar): Observable<void> {
    const params = new HttpParams()
      .set('key', environment.key.toString())
      .set('country', environment.extCode)
      .set('userauth', this.token.get())
      .set('avatarId', avatar.id.toString());
    return this.http
      .delete<ApiResponse<void>>(`${environment.api}/user_delete_avatar`, { params })
      .pipe(
        map(res => {
          this.onAvatarDelete.next( avatar );
          return;
        })
      );
  }

  /**
   * Set the main avatar of the user
   * @param {Avatar} avatar the avatar which will be main
   * @returns {Observable<void>} An empty observable
   * @throws {Error} An error if the setting failed
   * @see http://chat_api-pp.contactdve.com/doc/#api-Media-User_select_avatar
   */
  defineMainAvatar(avatar: Avatar): Observable<void> {
    const body = {
      key: environment.key.toString(),
      country: environment.extCode,
      userauth: this.token.get(),
      avatarId: avatar.id
    };
    return this.http
      .put<ApiResponse<void>>(`${environment.api}/user_select_avatar`, body )
      .pipe(
        map(res => {
          this.onMainAvatarChange.next(avatar);
          return;
        })
      );
  }
}
