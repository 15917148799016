import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SignUpType } from 'src/app/shared/enums/sign-up-type.enum';
import { UserService } from 'src/app/account/user.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent {

  /** Sign-up methods enum */
  readonly SignUpType = SignUpType;

  /** The site sign-up type */
  signUpType = environment.useBothSignUpMethod ? SignUpType.both : SignUpType.email;
  email: string;
  submitted = false;
  success = false;

  constructor(
    private user: UserService,
  ) { }

  onSubmit() {
    this.submitted = true;
    this.user
      .sendPasswordRecovery(this.email)
      .subscribe(
        res => {
          this.submitted = false;
          this.success = res;
        },
      );
  }

}
