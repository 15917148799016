import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { AvatarService } from '../account-profil-avatars/avatar.service';
import { Angulartics2 } from 'angulartics2';
import { MatStepper } from '@angular/material';
import { User } from 'src/app/shared/interfaces/user';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-account-profil-wizzard',
  templateUrl: './account-profil-wizzard.component.html',
  styleUrls: ['./account-profil-wizzard.component.scss']
})
export class AccountProfilWizzardComponent implements OnInit {
  @Input () me: User;
  @Output () closing = new EventEmitter<boolean>();
  @Output () showing = new EventEmitter<boolean>();
  @ViewChild ('stepper') step: MatStepper;
  newImage: FileEvent;
  newAvatar = null;
  crop = false;
  nickname: string;
  enableSave = false;
  submitted = false;
  previousNickname: string;

  addPhoto = false;
  addPseudo = false;
  sendPseudoTracker = true;

  constructor(
    private avatars: AvatarService,
    private users: UserService,
    private angulartics2: Angulartics2
    ) { }

  ngOnInit() {
    this.previousNickname = this.me.nickname;
  }
  saveProfil() {
    this.submitted = true;
    this.users.update(this.me)
      .subscribe(
        () => {
          this.addPseudo = true;
          const label = this.addPhoto ? 'photopseudo' : 'pseudo';
          this.angulartics2.eventTrack.next({
            action: 'clic_save_modification',
            properties: {
              category: 'cat_popup_photo_pseudo',
            },
          });
          this.submitted = false;
          this.closing.emit(true);
        }
      );
  }
  dataListener($event) {
    this.enableSave = $event.value !== this.previousNickname;
    if (this.sendPseudoTracker && $event.value.length > 2) {
      this.sendPseudoTracker = false;
      this.angulartics2.eventTrack.next({
        action: 'clic_add_pseudo',
        properties: {
          category: 'cat_popup_photo_pseudo',
        },
      });
    }
  }
  onPhotoInputChange($event: FileEvent): void {
    this.newImage = $event;
    this.crop = true;
  }
  closeWizzard() {
    this.closing.emit(true);
    const label = this.addPhoto ? 'photo' : 'photopseudoko';
    this.angulartics2.eventTrack.next({
      action: 'clic_save_modification',
      properties: {
        category: 'cat_popup_photo_pseudo',
        label
      },
    });
  }
  showProfil() {
    this.showing.emit(true);
  }
  closeCrop($event) {
    this.crop = $event;
  }
  cropImage($img: string) {
    console.log ('crop image from wizzard');
    this.crop = false;
    this.avatars.uploadAvatar($img)
    .subscribe(avatar => {
      this.me.avatars.splice(1, 0, avatar);
      // profile cropped photo tracker
      this.angulartics2.eventTrack.next({
        action: 'clic_save_picture',
        properties: {
          category: 'cat_popup_photo_pseudo',
        },
      });
      this.angulartics2.eventTrack.next({
        action: 'crop_photo_done',
        properties: {
          category: 'cat_popup_photo_pseudo',
        },
      });
      this.addPhoto = true;
      // this.loadingAvatar = false;
      this.me.avatars = this.me.avatars.filter(a => a.id !== null);
      this.newAvatar = avatar.thumb;
      this.step.next();
      if (this.me.avatars.length <= 1) {
        this.me.avatar = avatar;
        this.avatars.defineMainAvatar(avatar)
          .subscribe(
            () => {
              const use = this.me;
              use.avatar = avatar;
              use.avatars.splice(use.avatars.indexOf(avatar), 1);
              use.avatars.splice(0, 0, avatar);
            },
          );
      }
    });
  }
}
