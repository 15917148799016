<div class="overlay" @fade></div>
<div class="background">
  <div class="background-ripple" @ripple></div>
  <div class="background-border background-element" @plop></div>
  <div class="background-plain background-element" @plop2></div>
</div>
<div class="error-content">
  <ng-container [ngSwitch]="error">

    <ng-container *ngSwitchCase="'BadCredentials'" i18n="@@BadCredentials">error</ng-container>
    <ng-container *ngSwitchCase="'UnknownErrorSignIn'" i18n="@@UnknownErrorSignIn">error</ng-container>
    <ng-container *ngSwitchCase="'ConnexionPasswordEmpty'" i18n="@@ConnexionPasswordEmpty">error</ng-container>
    <ng-container *ngSwitchCase="'ConnexionLoginEmpty'" i18n="@@ConnexionLoginEmpty">error</ng-container>
    <ng-container *ngSwitchCase="'AccountBanned'" i18n="@@AccountBanned">error</ng-container>
    <ng-container *ngSwitchCase="'AccountDeleted'" i18n="@@AccountDeleted">error</ng-container>
    <ng-container *ngSwitchCase="'BadLogin'" i18n="@@BadLogin">error</ng-container>
    <ng-container *ngSwitchCase="'ValidationInvalidPhoneNumber'" i18n="@@ValidationInvalidPhoneNumber">error</ng-container>
    <ng-container *ngSwitchCase="'ValidationUniquePhoneNumber'" i18n="@@ValidationUniquePhoneNumber">error</ng-container>
    <ng-container *ngSwitchCase="'NotEmailOrPhoneError'" i18n="@@NotEmailOrPhoneError">error</ng-container>
    <ng-container *ngSwitchCase="'PseudoIsNumeric'" i18n="@@PseudoIsNumeric">error</ng-container>
    <ng-container *ngSwitchCase="'uniqueLogin'" i18n="@@uniqueLogin">error</ng-container>
    <ng-container *ngSwitchCase="'shortLogin'" i18n="@@shortLogin">error</ng-container>
    <ng-container *ngSwitchCase="'MyProfileEmailUnique'" i18n="@@MyProfileEmailUnique">error</ng-container>
    <ng-container *ngSwitchCase="'badEmailFormat'" i18n="@@badEmailFormat">error</ng-container>
    <ng-container *ngSwitchCase="'PasswordRecuperationWrongEmailOrPhone'" i18n="@@PasswordRecuperationWrongEmailOrPhone">error</ng-container>
    <ng-container *ngSwitchCase="'PasswordRecuperationWrongEmail'" i18n="@@PasswordRecuperationWrongEmail">error</ng-container>
    <ng-container *ngSwitchCase="'ValidationRequiredEmail'" i18n="@@ValidationRequiredEmail">error</ng-container>
    <ng-container *ngSwitchCase="'UnavailabilityService'" i18n="@@UnavailabilityService">error</ng-container>
    <ng-container *ngSwitchCase="'NotEnoughCredit'" i18n="@@NotEnoughCredit">error</ng-container>
    <ng-container *ngSwitchCase="'NonSubscriber'" i18n="@@NonSubscriber">error</ng-container>
    <ng-container *ngSwitchCase="'TooShortPassword'" i18n="@@TooShortPassword">bad password {{minLogin}} {{maxLogin}}</ng-container>
    <ng-container *ngSwitchCase="'BadPassword'" i18n="@@BadPassword">bad password {{min}} {{max}}</ng-container>
    <ng-container *ngSwitchDefault i18n="@@ConnexionErrorService">error</ng-container>

  </ng-container>
  <button class="button button-auto accent" (click)="close()">ok !</button>
</div>
