<div class="connection-holder">
  <h2 i18n="titre deja un  compte signin@@AlreadySubscribe">Déjà un compte ?</h2>
  <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
    <div class="container">
      <label class="login-input">
        <mat-icon svgIcon="man"></mat-icon>
        <mat-form-field>
          <input matInput type="text" name="login" i18n-placeholder="Placeholder du champs identifiant du formulaire de connexion@@LoginPlaceholder"
            placeholder="Login" [(ngModel)]="login" #loginControl="ngModel" required>
          <mat-error *ngIf="loginControl.hasError('required')" i18n="Lors de la connexion le user de complète pas son pseudo@@ConnexionLoginEmpty">Veuillez
            entrer un pseudo valide</mat-error>
        </mat-form-field>
      </label>
      <label>
       <mat-icon svgIcon="key"></mat-icon>
        <mat-form-field>
          <input matInput type="password" name="password" i18n-placeholder="Placeholder du champs mot de passe du formulaire de connexion@@PasswordPlaceholder"
            placeholder="Mot de passe" [(ngModel)]="password" #passwordControl="ngModel" required>
          <mat-error *ngIf="passwordControl.hasError('required')" i18n="Lors de la connexion le user ne complète pas son mot de passe@@ConnexionPasswordEmpty">Veuillez
            entrer un mot de passe valide</mat-error>
        </mat-form-field>
      </label>
      <a routerLink="/forget-password" i18n="Lien vers la page de récupération du mot de passe@@ForgottenPassword">Mot
        de passe oublié ?</a>
    </div>

    <button class="button button-finger accent" type="submit" i18n="Bouton de connexion@@ConnexionHomePage">Connexion</button>
  </form>
  <h2 i18n="titre rejoingnez nous signin@@RegistrationHomePage">Rejoignez nous !</h2>
  <div class="other-button">
    <a [attr.href]="lp" *ngIf="!free else routing">
    <button class="button button-finger primary" type="submit" i18n="Lien vers la page d'inscription@@SignUp">S'inscrire</button></a>
  </div>
  <h2 i18n="titre suivez nous signin@@FollowUsSocialNetwork"
  *ngIf="facebook.available || twitter.available || instagram.available">Suivez nous !</h2>
  <div class="other-button-social">
    <a [href]="facebook.link" target="_blank" *ngIf="facebook.available"><mat-icon svgIcon="facebook" class="facebook"></mat-icon></a>
    <a [href]="twitter.link" target="_blank" *ngIf="twitter.available"><mat-icon svgIcon="twitter" class="twitter"></mat-icon></a>
    <a [href]="instagram.link" target="_blank" *ngIf="instagram.available"><mat-icon svgIcon="instagram" class="instagram"></mat-icon></a>
  </div>
</div>
<div class="icon-offline">
  <mat-icon svgIcon="nointernet"></mat-icon>
</div>

<ng-template #routing>
  <button routerLink="/completeProfile" class="button button-finger primary" type="submit" i18n="Lien vers la page d'inscriptioninscription@@SignUp">S'inscrire</button>
</ng-template>
<ng-container *ngIf="loading">
  <div class="loader">
    <div class="loader-overlay"></div>
    <div class="loader-ripple"></div>
    <div class="loader-content">
      <mat-icon svgIcon="man"></mat-icon>
    </div>
  </div>
</ng-container>
