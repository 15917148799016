import { NgModule } from '@angular/core';
import {
  MatIconModule,
  MatInputModule,
  MatButtonModule,
  MatSliderModule,
  MatFormFieldModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatRadioModule,
  MatStepperModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  exports: [
    MatRadioModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSliderModule,
    MatFormFieldModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatSelectModule,
    FlexLayoutModule
  ]
})
export class MaterialModule { }
